module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-179045573-2","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"quality":100,"showCaptions":true,"maxWidth":650,"linkImagesToOriginal":true,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Bao Bì Duy Nhật | DN Pack | Nhanh Chóng - Chính Xác - Chuyên Nghiệp","short_name":"Bao Bì Duy Nhật | Nhanh Chóng - Chính Xác - Chuyên Nghiệp","start_url":"https://www.baobiduynhat.com.vn/index.html","background_color":"#dbdbdb","theme_color":"#f7f0eb","display":"standalone","icon":"src/images/duynhat/logo/DN_logo.png","icon_options":{"purpose":"any maskable"},"lang":"en","description":"Bao Bì Duy Nhật chuyên sản xuất in ấn và cung cấp các loại bao bì giấy, bao bì màng ghép phức hợp cao cấp cho nông – lâm nghiệp, thủy – hải sản, hàng tiêu dùng.","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"c47499fe3253bed19874666140448428"},
    },{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/","/gioi-thieu/","/nha-may/","/tin-tuc/","/san-pham/","/lien-he/"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
